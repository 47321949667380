export interface IFeaturesResponse {
  feature_flags: IResponseFeature[];
}

interface IResponseFeature {
  name: string;
  value: boolean;
}

export enum FeatureNames {
  TestFeature = 'testFeature',
  ChatsListPagination = 'chatsListPagination',
  RichTextEditor = 'richTextEditor',
  DisturbingContentDetection = 'disturbingContentDetection',
  CustomerIntentEnabled = 'customerIntentEnabled',
  ReviewsReportEnabled = 'reviewsReportEnabled',
  InsightsReportsEnabled = 'recentInsightsEnabled',
  ShowHelloTextProduct = 'showHelloTextProduct',
  AISuggestions = 'aiSuggestions',
  ProductSwitcher = 'productSwitcher',
  PriorityChat = 'priorityChat',
  KnowledgeHubAddWebsiteOnboarding = 'knowledgeHubAddWebsiteOnboarding',
  CopilotStreaming = 'copilotStreaming',
  AICannedInChips = 'aiCannedInChips',
  ReplySuggestionsFullMessageContext = 'replySuggestionsFullMessageContext',
}

export type Features = Record<FeatureNames, boolean>;
