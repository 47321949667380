import { type IFeaturesResponse, type Features, FeatureNames } from './interfaces';

const knownFeatures = new Map<string, string>([
  ['testFeature', 'test_feature'],
  [FeatureNames.ChatsListPagination, 'chats_list_pagination'],
  [FeatureNames.RichTextEditor, 'rich_text_editor'],
  [FeatureNames.DisturbingContentDetection, 'disturbing_content_detection'],
  [FeatureNames.CustomerIntentEnabled, 'customer_intent_enabled'],
  [FeatureNames.ReviewsReportEnabled, 'reviews_report_enabled'],
  [FeatureNames.InsightsReportsEnabled, 'recent_insights_enabled'],
  [FeatureNames.ShowHelloTextProduct, 'show_hello_text_product'],
  [FeatureNames.AISuggestions, 'ai_suggestions'],
  [FeatureNames.ProductSwitcher, 'product_switcher'],
  [FeatureNames.PriorityChat, 'priority_chat'],
  [FeatureNames.KnowledgeHubAddWebsiteOnboarding, 'knowledge_hub_add_website_onboarding'],
  [FeatureNames.CopilotStreaming, 'copilot_streaming'],
  [FeatureNames.AICannedInChips, 'ai_canned_in_chips'],
  [FeatureNames.ReplySuggestionsFullMessageContext, 'rs-full-messages-context'],
]);

export const defaultFeatures: Record<FeatureNames, boolean> = {
  [FeatureNames.TestFeature]: false,
  [FeatureNames.ChatsListPagination]: false,
  [FeatureNames.RichTextEditor]: false,
  [FeatureNames.DisturbingContentDetection]: false,
  [FeatureNames.CustomerIntentEnabled]: false,
  [FeatureNames.ReviewsReportEnabled]: true,
  [FeatureNames.InsightsReportsEnabled]: false,
  [FeatureNames.ShowHelloTextProduct]: false,
  [FeatureNames.AISuggestions]: false,
  [FeatureNames.ProductSwitcher]: false,
  [FeatureNames.PriorityChat]: false,
  [FeatureNames.KnowledgeHubAddWebsiteOnboarding]: false,
  [FeatureNames.CopilotStreaming]: false,
  [FeatureNames.AICannedInChips]: false,
  [FeatureNames.ReplySuggestionsFullMessageContext]: false,
};

export function deserializeFeatures(features: IFeaturesResponse): Features {
  return Object.entries(defaultFeatures).reduce((result, [key, value]) => {
    const feature = features.feature_flags.find(({ name }) => name === knownFeatures.get(key));
    result[key] = feature?.value ?? value;

    return result;
  }, {} as Features);
}
